//Customer Experience Digital Data Layer [Adobe Analytics - Must be GLOBAL :)]
//spec: https://www.w3.org/2013/12/ceddl-201312.pdf
//
// Initial Object
window.digitalData = {
  page: {
    pageInfo: {}
  },
  event: {},
  user: {},
  version: '1.0',
};

if (document.referrer) {
  // Ignores referrer if domain starts with "account." and ends with "lifeway.com".  This should capture all
  // environemnts of account.lifeway.com & mlc local environments

  //Object.defineProperty(document, "referrer", {get : function(){ return "https://www.lifeway.com"; }});
  //^^^ Use to test referrer in browser if needed  ^^^//

  const matches = document.referrer.match(/(http.*:\/\/(?!account|mlc|my\..*lifeway\.com).[^/]+)/);
  if (matches) {
    sessionStorage.setItem('referrer', matches[1]);
  }
}
